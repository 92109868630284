
.page {
    position: absolute;
    top: 0rem;
    min-height: 100vh;
    color: white;
    width: 100vw;
    font-size: 25px;
}

.image_name{
    text-align: center;
}

.cards {
    display: grid;
    grid-template-columns: repeat(2, minmax(0,1fr));
    padding: 5rem;
}


.projects{
    display:flex ;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    font-size: 5rem;
}

.projects h1{
    font-size: 5.5rem;
}
.card {
    object-fit: contain;
    max-width: 500px;
    width: 100%;
    position: relative;
    margin: auto;
    height: auto;
    aspect-ratio: 9/16;
    position: relative;
    color: #fff;
    transition: transform 0.1s ease;
    transform-style: preserve-3d;
    will-change: transform;
}

  /* Slight parallax effect on hover */
.card:hover {
    transform: translateZ(12px);
}


@media (max-width: 768px) {
    .projects{
        line-height: 15px;
        font-size: 20px;
        gap: 20px;
        margin-top: 5rem;
        justify-content: center;
    }

    .projects h1{
        font-size: 3rem;
    }

    .cards{
        grid-template-columns: repeat(1, minmax(0,1fr));
        padding-left: 5rem;
        column-gap: 25px;
        padding-top: 2rem;
    }
    .image_name {
        font-size: 10px;
    }
    .card{
        max-height: 800px;
        gap: 10px;
    }
}

@media (max-width: 1080px) {
    .projects{
        line-height: 35px;
        font-size: 30px;
        gap: 40px;
        justify-content: center;
    }
    .cards{
        padding-left: 6rem;
        column-gap: 40px;
        padding-top: 3rem;
    }
    .image_name{
        font-size: 15px;
    }
    .card{
        max-height: 1000px;
        gap: 15px;
    }
}



