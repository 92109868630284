.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#contact {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading h3 {
    text-align: center;
    font-size: 50px;
    color: var(--beige);
}

.btn {
    height: 3rem;
    border: 2px solid var(--beige);
    border-radius: 10px;
    transition: all 1s ease-in-out;
    margin: 0 20%;
}

.btn:hover {
    background-color: var(--beige);
    color: black;
    font-weight: bold;
    transition: all 1s ease-in-out;
}

form {
    display: flex;
    gap: 1.2rem;
    align-items: flex-start;
    justify-content: flex-start;
    color: white;
    width: 40vw;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
}

input,
textarea {
    outline: transparent;
    padding: 1.5rem;
    font-size: large;
    background: black;
    border: none;
    border-bottom: 2px solid var(--beige);
    resize: none;
    color: white;
}


input {
    margin-left: 1rem;
    width: 50%;
}

textarea {
    width: 100%;
    border: 2px solid var(--beige);
    border-radius: 10px;
}

@media screen and (max-width: 1024px) {
    .container button {
        width: 25vw;
    }

    input,
    textarea {
        font-size: small;
        padding: 0.5rem;
    }
}


@media screen and (max-width: 600px) {
    button {
        width: 90%;
    }

    p input,
    textarea {
        font-size: small;
        width: 90%;
    }

    form {
        width: 80vw;
    }
}

@media screen and (max-width: 1024px) {
    button {
        width: 50vw;
        height: 20px;
        font-size: 3vw;
    }

    input,
    textarea {
        font-size: 3vw;
    }
    .heading h1{
        font-size: 5vw;
    }
    .heading h3{
        font-size: 4vw;
    }
    
}