@keyframes appearRight {
    0% {
        transform: translate(100%);
        -webkit-transform: translate(100%);
        -moz-transform: translate(100%);
        -ms-transform: translate(100%);
        -o-transform: translate(100%);
    }

    100% {
        transform: translate(0%);
        -webkit-transform: translate(0%);
        -moz-transform: translate(0%);
        -ms-transform: translate(0%);
        -o-transform: translate(0%);
    }
}

@keyframes appearLeft {
    0% {
        transform: translate(-100%);
        -webkit-transform: translate(-100%);
        -moz-transform: translate(-100%);
        -ms-transform: translate(-100%);
        -o-transform: translate(-100%);
    }

    100% {
        transform: translate(0%);
        -webkit-transform: translate(0%);
        -moz-transform: translate(0%);
        -ms-transform: translate(0%);
        -o-transform: translate(0%);
    }
}

@keyframes rotate {
    0% {
        rotate: 0deg;
    }

    20% {
        rotate: 180deg;
    }

    40% {
        rotate: 360deg;
    }

    60% {
        rotate: 540deg;
    }

    80% {
        rotate: 720deg;
    }

    100% {
        rotate: 900deg;
    }
}

@font-face {
    font-family: founders;
    src: url("../fontfamily/TestFoundersGrotesk-Regular.otf");
}

@font-face {
    font-family: mixta;
    src: url(../fontfamily/Fontspring-DEMO-mixtaessdidone-regular.otf);
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.container {
    padding-top: 20rem;
    background-color: black;
    height: 90vh;
    width: 100vw;
}

.mainHeading {
    width: 100%;
    height: max-content;
}

.mainHeading h1 {
    color: white;
    font-family: mixta;
    font-size: 25vw;
    text-align: center;
    line-height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mainHeading span {
    color: white;
    position: absolute;
    font-family: mixta;
    left: 5%;
    font-size: 3rem;
}

.contentBox {
    display: flex;
    background-color: rgb(0, 0, 0);
    height: 50vh;
    width: 100%;
    padding: 2rem 8rem;
}

.leftSection {
    width: 30%;
    display: flex;
}


.rightSection {
    width: 70%;
    overflow: hidden;
}



.spikedCircle {
    transition: 1s;
    border-radius: 50%;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 8s;
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25rem;
    aspect-ratio: 1;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.spikedCircle hr {
    position: absolute;
    width: 100%;
    background-color: white;
    height: 2px;
}


@keyframes hr1OpenClose {
    0% {
        rotate: 0deg;
    }

    20% {
        rotate: -45deg;
    }

    80% {
        rotate: -45deg;
    }

    100% {
        rotate: 0deg;
    }
}

@keyframes hr2OpenClose {
    0% {
        rotate: 0deg;
    }

    20% {
        rotate: -135deg;
    }

    80% {
        rotate: -135deg;
    }

    100% {
        rotate: 0deg;
    }
}

@keyframes hr3OpenClose {
    0% {
        rotate: 0deg;
    }

    20% {
        rotate: -270deg;
    }

    80% {
        rotate: -270deg;
    }

    100% {
        rotate: 0deg;
    }
}

.spikedCircle hr:nth-child(1) {
    animation: hr1OpenClose 4s linear infinite;
    -webkit-animation: hr1OpenClose 4s linear infinite;
}

.spikedCircle hr:nth-child(2) {
    animation: hr2OpenClose 4s linear infinite;
    -webkit-animation: hr2OpenClose 4s linear infinite;
}

.spikedCircle hr:nth-child(3) {
    animation: hr3OpenClose 4s linear infinite;
    -webkit-animation: hr3OpenClose 4s linear infinite;
}

.numberBox {
    position: relative;
    top: 10px;
    color: white;
    overflow: hidden;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
}

.slider {
    color: var(--beige);
    align-items: center;
    justify-content: space-around;
    font-size: 2rem;
    display: flex;
    height: 200%;
    width: 100%;
    flex-direction: column;
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%);
}

.topBar {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
}

.topBar h1 {
    animation: appear 0.5s;
    color: var(--beige);
    text-align: center;
    width: 70%;
    font-size: 2.5rem;
    -webkit-animation: appear 0.5s;
}

.arrowBox {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.arrowBox button {
    z-index: 5;
    height: 40px;
    width: 40px;
}

.arrowBox button:disabled {
    z-index: 5;
    filter: brightness(10%);
    -webkit-filter: brightness(10%);
}


.appearFromRight {
    animation: appearRight 1s;
    -webkit-animation: appearRight 1s;
}

.appearFromLeft {
    animation: appearLeft 1s;
    -webkit-animation: appearLeft 1s;
}

.rightSection p {
    animation: appear 0.5s;
    transition: 0.5s;
    color: aliceblue;
    text-align: justify;
    font-size: large;
    padding: 1rem 2rem;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}


@media screen and (max-width:1400px) {

    .container *{
        padding: 0;
    }

    .mainHeading h1 {
        line-height: 100%;
        font-size: 25vw;
    }

    .spikedCircle {
        height: 25vw;
    }

    .mainHeading span {
        transform: translateY(-50px);
        -webkit-transform: translateY(-50px);
        -moz-transform: translateY(-50px);
        -ms-transform: translateY(-50px);
        -o-transform: translateY(-50px);
    }
    .leftSection{
        width: 10%;
    }

    .rightSection{
        width: 90%;
    }

    .topBar h1{
        width: 100%;
        font-size: 3.5vw;
    }
    .arrowBox{
        width: 20%;
    }

    .rightSection p{
        position: relative;
        width: 90vw;
        font-size: 1rem;
        left: -5vw;
    }

    .arrowBox button{
        z-index: 5;
        width: 35%;
        aspect-ratio: 1;
    }
}