@font-face {
    font-family: founders;
    src: url("../fontfamily/TestFoundersGrotesk-Regular.otf");
}

@keyframes render{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes enter {
    0% {
        transform: translateY(80px);
        opacity: 0;
        background-color: beige;
        rotate: 5deg;
        -webkit-transform: translateY(80px);
        -moz-transform: translateY(80px);
        -ms-transform: translateY(80px);
        -o-transform: translateY(80px);
}
   

    100% {
        opacity: 1;
        top: auto;
        rotate: 0deg;
        transform: translate(0px);
        -webkit-transform: translate(0px);
        -moz-transform: translate(0px);
        -ms-transform: translate(0px);
        -o-transform: translate(0px);
    }
}

* {
    font-family: founders;
    letter-spacing: 5px;
}

@font-face {
    font-family: mixta;
    src: url("../fontfamily/Fontspring-DEMO-mixtaesssharp-black.otf");
}

.poster {
    transition: 1s ease;
    height: 100vh;
    width: 100vw;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    -webkit-transition: 1s ease;
    -moz-transition: 1s ease;
    -ms-transition: 1s ease;
    -o-transition: 1s ease;
}

.workLine {
    animation: enter 1.5s;
    color: var(--beige);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: founders_grostek;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    -webkit-animation: enter 1.5s;
}


.motto {
    z-index: 1;
    position: relative;
    animation: enter 1.5s;
    text-align: center;
    line-height: 7.25rem;
    font-family: mixtas-pro;
    font-size: 4.5rem;
    -webkit-animation: enter 1.5s;
}


@media screen and (max-width:650px) {
    .poster {
        transition: 1s ease;
        height: 100vh;
        width: 100vw;
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        -webkit-transition: 1s ease;
        -moz-transition: 1s ease;
        -ms-transition: 1s ease;
        -o-transition: 1s ease;
    }
    
    .workLine {
        animation: enter 1.5s;
        color: var(--beige);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: founders_grostek;
        margin-bottom: 1.5rem;
        font-size: 3vw;
        line-height: 1.25rem;
        text-transform: uppercase;
        letter-spacing: 0.3em;
        -webkit-animation: enter 1.5s;
    }
    
    
    .motto{
        z-index: 1;
        position: relative;
        animation: WelcomePage_enter__FpUeR 1.5s;
        text-align: center;
        line-height: 3.25rem;
        font-family: mixtas-pro;
        font-size: 8vw;
        -webkit-animation: WelcomePage_enter__FpUeR 1.5s;
    }
    
}
